
import router from '@/router';
import {
  IonButtons,
  
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  alertController,
  actionSheetController ,
  ///IonToggle
  
  } from '@ionic/vue';
  
import common from  "../js/common"
 import Download from '@/components/ScaricamentoContenuti.vue';

//import { defineComponent } from 'vue';
export default ({
  name: "langSwitch",
  props: {
    title: { type: String, default: 'Default Title' }
  },
  components: {
    IonButtons,
   
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    //IonToggle
    
  },
  data(){
    return{
     currPerc:null,
      saved:[],
      remaining:[],
      currLang:this.currentLang,
      attivaSupporto:0

    }
  },
  setup(){
   
    return{
      
    }
  },
  computed:{

    supportoVisivo(){
      const pubblication=JSON.parse(localStorage.getItem('pubblication'));
      if(pubblication.hasOwnProperty("supporto_video")){
        return pubblication.supporto_video

      }else{
        return null
      }
      

    },
    getAttivaSuppoto(){
      const supporto=JSON.parse(localStorage.getItem('attivaSupporto'));
      if(supporto){
        if(supporto==true){
          return true
        }else{
          return false
        }

      }else{
        return false
      }
    },

    
    savedLangs:{
        get() {
          console.log("linguee ", localStorage.getItem('savedLangs'))
          const langsets= [];
          const savedLangsPerc=JSON.parse(localStorage.getItem('sPercLang')).find(p=> p.perc==this.currPerc)
          savedLangsPerc.langs.forEach(lan=>{
            langsets.push(lan.lang);

          })
          this.assignSaved(langsets)
          console.log("linguee savedLangs ", langsets)
          return langsets
        },
      // setter
      set(newLang) {
      
        this.saved.push(newLang);
        localStorage.setItem('savedLangs', this.saved)
      }

    },

    remainingLang(){
      const pubblication=JSON.parse(localStorage.getItem('pubblication'))
      const publishedLang=pubblication.lang;
      this.currPubbDate(pubblication);
     
      const myArray = publishedLang.filter( ( el ) =>{
        console.log("?? "+!this.savedLangs.includes( el ));
        return !this.savedLangs.includes( el);
      
      });
      this.assignRemaining(myArray);
    return myArray;

    }

    
  },
  created(){
    this.datetoVersion=common.datetoVersion;
    this.networkError=common.networkError;
    this.checkStatus=common.checkOnlineStatus;

    this.emitter.on('addLang', (lang)=>{
      this.remaining=this.remaining.filter(item => item !== lang);
      this.addLang(lang);
    })



  },
  mounted(){
    
    this.currPerc=localStorage.getItem("percSel")
    
    this.savedLangs
    this.remainingLang
   this.attivaSupporto=this.getAttivaSuppoto;
  
    this.currLang=localStorage.getItem("lang")

  
    
  },

  methods:{

    notice(){
      console.log("fire notice")
      localStorage.setItem('attivaSupporto', this.attivaSupporto)
    },

    getversionLangs(){
       
      let versionLangs= [];
      console.log("linguee ", localStorage.getItem('sPercLang'))
      versionLangs=  JSON.parse(localStorage.getItem('sPercLang'));
      const versionLangsPerc=versionLangs.find(p=>p.perc==this.currPerc)
      console.log("linguee sPercLang ", versionLangsPerc)
          
    return versionLangsPerc.langs;
         
     },
    addLang(lang){
      console.log("ADDO LANG", lang)
      this.savedLangs=lang;

      if(this.currPerc){
        const getsaved=  JSON.parse(localStorage.getItem('savedPerc'));
       // let oggsaved=JSON.parse(getsaved);
       getsaved[lang].push(this.currPerc);
       console.log("GSAVED ", getsaved);
       localStorage.setItem('savedPerc', JSON.stringify(getsaved))
      }
    },
    checkIfActive(lang){
      console.log("checkIfActivelang p")
      if(this.currLang==lang){
        console.log("checkIfActive lang dentro")
        return "checked"
      }
    },
    pushPage(passedLang) {
      const ionNav = document.querySelector('ion-nav') as any;
      if(this.currPerc==null){
        ionNav.push(Download,  { lang: passedLang , fromC:"lang"});
      }else{
        ionNav.push(Download,  { lang: passedLang , fromC:"lang", perc:this.currPerc});
      }
      
     
    },
    pushPageAggiorna(passedLang) {
      const ionNav = document.querySelector('ion-nav') as any;
    
      if(this.currPerc==null){
        ionNav.push(Download,  { lang: passedLang , fromC:"update"});
      }else{
        ionNav.push(Download,  { lang: passedLang , fromC:"update", perc:this.currPerc});
      }
     
    },
    buttons(){
      const remainingArray=[];
        this.remaining.forEach(element => {
          const langtoPush={
            text: this.$t('menu.lang.'+element),
            handler: () => {
              
                console.log('clicked')
                this.add(element)
              },
          }
          remainingArray.push(langtoPush);
         
        });
        const cancelbutton={
              text: this.$t('action.cancel'),
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked')
              },
            }
             remainingArray.push(cancelbutton)
             console.log(remainingArray);
        return remainingArray
      },
    async presentActionSheet() {
        const actionSheet = await actionSheetController.create({
            header: this.$t('menu.lang.select'),
            cssClass: 'my-custom-class',
            buttons: this.buttons(),
          });
        await actionSheet.present();

       await actionSheet.onDidDismiss();
        console.log('onDidDismiss');
      },

    async showOptions(lang) {
      const alert = await alertController.create({
        header: this.$t('menu.lang.add') ,
        message:  this.$t('menu.lang.alert') ,
        buttons: [
          {
            text: this.$t('action.download'),
            cssClass:'modal-accept-button',
            handler: async() => {
              console.log("Accepted");
              const online= await this.checkStatus();
              console.log("onlòineSTATUS ",online);
              if(online){
                const pubblication=JSON.parse(localStorage.getItem("pubblication"))
                const suppLang=pubblication.lang.find(el=> el==lang);
                  //this.savedLangs=lang;
                  //this.remaining=this.remaining.filter(item => item !== lang);
                if(suppLang){
                    //this.searchMedia(lang);
                  // this.$router.replace({ path: "/scarica/"+ lang});
                    this.pushPage(lang);
                }else{
                    //this.searchMedia('it');
                    //this.$router.replace({ path: "/scarica/it"});
                  this.pushPage("it");
                }
              }else{
                console.log("NON C?èRETE");
                this.networkError();
              }
            
            
            },
          },
          {
            text: this.$t('action.cancel') ,
            role: "cancel",
          },
        ],
      });

      await alert.present();
    },

    async updateLang(lang) {
            if(window.navigator.onLine){
                 const alert = await alertController.create({
                    header: this.$t('update.title') ,
                    message: this.$t('update.text') ,
                    buttons: [
                        
                        {
                            text: this.$t('action.postponi') ,
                            role: "cancel",
                            handler: () => {
                                console.log("Declined the offer");
                                
                            },
                        },
                        {
                            text:this.$t('action.download'),
                            cssClass:'modal-accept-button',
                            handler: () => {
                                console.log("Accepted");
                                this.emitter.emit('aggiorna', "menu");
                                this.pushPageAggiorna(lang);
                            
                            },
                        },
                    ],
                });

                await alert.present();

            }else{
                this.networkError();
            }
           
        },

    currPubbDate(pubblication){
      this.pubblication=pubblication;
    },

    checkVersion(lang){
      const currentVersion=this.datetoVersion(this.pubblication.pubblicazione);
      const langVersion=this.getversionLangs().find(el=> el.lang==lang).vers;

      if(currentVersion>langVersion){
        console.log("LA LINGUA NON è AGGIORNATA"); 
        this.updateLang(lang);
      }else{
        console.log("versione ok"); 
      }

    },

    switchLang(lang){
       /* if (this.$i18n.locale !== lang) {
           this.$i18n.locale = lang;
           localStorage.setItem('lang', lang);
        }*/
        if (localStorage.getItem('lang')!= lang) {
          localStorage.setItem('lang', lang);
          this.currLang=lang;
          this.$i18n.locale = lang;

          this.emitter.emit('updateLang', this.currLang);
        }
        this.checkVersion(lang);

    
    },
    add(lang){

      this.showOptions(lang)
    /*  this.savedLangs=lang;
      this.remaining=this.remaining.filter(item => item !== lang);*/
    },
    assignSaved(savedLangs){
      this.saved=savedLangs;
    },
    assignRemaining(remainingLangs){
      this.remaining=remainingLangs;
      console.log("REMAINING ",  this.remaining.length);

    },
  
    back(){
      const ionNav = document.querySelector('ion-nav') ;
            ionNav.pop();    
    },
   

  }
})
